// CORE
import { createAsyncThunk } from "@reduxjs/toolkit";

// SERVICES
import imageVersionService from "../../services/imageVersion";

const imageVersionAsyncActions = Object.freeze({
  getVersionByImageId  : createAsyncThunk("get/getVersionByImageId", imageVersionService.getVersionByImageId),
  activate             : createAsyncThunk("post/activateImageVersion", imageVersionService.activate),
  deactivate           : createAsyncThunk("delete/deactivateImageVersion", imageVersionService.deactivate),
  deleteVersion        : createAsyncThunk("delete/deleteImageVersion", imageVersionService.deleteVersion),
  setVersion           : createAsyncThunk("post/setImageVersion", imageVersionService.setVersion),
});

export default imageVersionAsyncActions;
