// CORE
import { createSlice } from '@reduxjs/toolkit';

// ACTIONS
import asyncActions from './asyncActions';
import empty        from '../../helpers/fp/array/empty';

const quotaSlice = createSlice({
  name: 'quota',
  initialState: empty(),
  reducers: {},
  extraReducers: builder => builder
    .addCase(asyncActions.getDefaultQuotas.fulfilled, (state, {payload}) => payload.data)
    .addCase(asyncActions.saveDefaultQuota.pending, (state, { meta }) => {
      const index = state.findIndex(defaultQuota => defaultQuota.image === meta.arg.image);
      if (index < 0) return state.push(meta.arg);
      return state.slice(0, index).concat(meta.arg, state.slice(index + 1));
    })
});

const quotaStore = Object.freeze({
  ...quotaSlice,
  asyncActions,
});

export default quotaStore;
