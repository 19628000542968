// AXIOS
import trap from "../../helpers/fp/decorators/trap";
import pathsJoin from "../../helpers/fp/url/pathsJoin";
import apiClient from "../../libs/apiClient";
import { USER_PATH } from "../user";

export const BILLING_SETTING_PATH = "billing-settings";

const billingSettingService = Object.freeze({
  getBillingSettingsByUserId          : trap((userId) => apiClient.get(pathsJoin([USER_PATH, userId, BILLING_SETTING_PATH]))),
  getBillingSettingById               : trap((billingSettingId) => apiClient.get(pathsJoin([BILLING_SETTING_PATH, billingSettingId]))),
  getBillingSettings                  : trap(() => apiClient.get(BILLING_SETTING_PATH)),
  getBillingSettingsPaginate          : ({ limit, page, order, search }) => apiClient.get(pathsJoin([BILLING_SETTING_PATH, page, "page"]), { params: { limit, order, search }}),
  getBillingSettingsCount             : trap(() => apiClient.get(pathsJoin([BILLING_SETTING_PATH, "count"]))),
  getPaymentMethodsByBillingSettingId : trap((billingSettingId) => apiClient.get(pathsJoin([BILLING_SETTING_PATH, billingSettingId, "payment-methods"]))),
  getCustomerByBillingSettingId       : trap((billingSettingId) => apiClient.get(pathsJoin([BILLING_SETTING_PATH, billingSettingId, "customer"]))),
  getPaymentType                      : trap((billingSettingId) => apiClient.get(pathsJoin([BILLING_SETTING_PATH, billingSettingId, "paymentType"]))),
  setAutomaticPaymentType             : trap((billingSettingId) => apiClient.post(pathsJoin([BILLING_SETTING_PATH, billingSettingId, "paymentType"]))),
  setManualPaymentType                : trap((billingSettingId) => apiClient.delete(pathsJoin([BILLING_SETTING_PATH, billingSettingId, "paymentType"]))),
});

export default billingSettingService;
