// CORE
import { createSlice } from "@reduxjs/toolkit";

// ACTIONS
import asyncActions from "./asyncActions";

// CONSTANTS
import emptyArray        from "../../helpers/fp/array/empty";
import updateManyPayload from "../../helpers/pp/array/updateManyPayload";
import updateOne from "../../helpers/pp/array/updateOne";

const sizeSlice = createSlice({
  name          : 'sizes',
  initialState  : emptyArray(),
  reducers      : {},
  extraReducers : builder => {
    builder
      .addCase(asyncActions.getSizes.fulfilled, updateManyPayload)
      .addCase(asyncActions.getSizeById.fulfilled, updateOne)
  },
});

const sizeStore = Object.freeze({
  ...sizeSlice,
  asyncActions,
});

export default sizeStore;
