// AXIOS
import apiClient from "../../libs/apiClient";

const getDefaultQuotas = () => apiClient.get(`/default-quotas`);
const saveDefaultQuota = ({ image, counts }) => apiClient.post(`/default-quotas`, { image, counts });

const defaultQutasService = Object.freeze({
  getDefaultQuotas,
  saveDefaultQuota,
});

export default defaultQutasService;
