// AXIOS
import toId from "../../helpers/toId";
import apiClient, { getTrap } from "../../libs/apiClient";


const getTeamQuotasByTeam = (team) => getTrap(`/teams/${toId(team)}/quotas`);
const saveTeamQuota       = ({ team, image, counts }) => apiClient.post(`/teams/${toId(team)}/quotas`, { image, counts });

const teamQuotaService = Object.freeze({
  getByTeam : getTeamQuotasByTeam,
  save      : saveTeamQuota,
});

export default teamQuotaService;
