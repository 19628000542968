// CORE
import { createSlice } from '@reduxjs/toolkit';
// ACTIONS
import asyncActions from './asyncActions';
// CONSTANTS
import { defaultPagination } from "../../constants/pagination";


const activitySlice = createSlice({
    name: 'activity',
    initialState: {
      items     : [],
      info      : defaultPagination,
      isLoading : false,
      isError   : false,
    },
    reducers: {
        clearActivities(state) {
            state.items = [];
            state.info = defaultPagination;

            return state;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(asyncActions.getActivitiesByResource.fulfilled, (state, {payload}) => {
                const {docs, ...pagination} = payload.data;
                state.items = docs;
                state.info = {...pagination}

                state.isLoading = false;
            })
            .addCase(asyncActions.getActivitiesByResource.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(asyncActions.getActivitiesByResource.rejected, (state) => {
                state.isLoading = false;
            })
    },
});

const activityStore = Object.freeze({
  ...activitySlice,
  asyncActions,
});

export default activityStore;
