import React from "react";
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";

// COMPONENTS
import NoMatch from "./pages/NoMatch/NoMatch";
import SuspenseRoute from "./components/shared/SuspenseRoute/SuspenseRoute";
import DashboardLayout from "./components/layouts/DashboardLayout/DashboardLayout";
import ProtectedRoute from "./components/shared/ProtectedRoute/ProtectedRoute";
import PublicRoute from "./components/shared/PublicRoute/PublicRoute";
import publicUrl from "./helpers/publicUrl";

// LAZY PAGES

const Login = React.lazy(() => import("./pages/Login/LoginContainer"));
const Teams = React.lazy(() => import("./pages/Teams"));
const TeamDetails = React.lazy(() => import("./pages/TeamDetails/TeamDetailsContainer"));
const Invoices = React.lazy(() => import("./pages/Invoices/InvoicesContainer"));
const InvoiceItem = React.lazy(() => import("./pages/InvoiceItem/InvoiceItemContainer"));
const Quotes = React.lazy(() => import("./pages/Quotes"));
const ServerModes = React.lazy(() => import("./pages/ServerModes"));
const CouponsPage = React.lazy(() => import("./pages/Coupons"));
const CouponPage = React.lazy(() => import("./pages/Coupons/ID"));
const PromosPage = React.lazy(() => import("./pages/Promos"));
const PromoPage = React.lazy(() => import("./pages/Promos/ID"));
const UsersPage = React.lazy(() => import("./pages/Users/Users"));
const UserDetailsPage = React.lazy(() => import("./pages/UserDetails/UserDetails"));
const PurgedAccounts = React.lazy(() => import("./pages/AccountDeletionRequests"));
const Resources = React.lazy(() => import("./pages/Resources"));
const ResourceDetails = React.lazy(() => import("./pages/ResourceDetails"));
const BillingSettings = React.lazy(() => import("./pages/BillingSettings"));
const BillingSetting = React.lazy(() => import("./pages/BillingSettings/ID"));
const ProvidersPage = React.lazy(() => import("./pages/Providers"));
const ImagesPage = React.lazy(() => import("./pages/Images"));
const SizesPage = React.lazy(() => import("./pages/Sizes"));
const ServerLogs = React.lazy(() => import("./pages/ServerLogs"));
const Tenants = React.lazy(() => import("./pages/Tenants"));
const Settings = React.lazy(() => import("./pages/Settings"));
const Theme = React.lazy(() => import("./pages/Theme"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <ProtectedRoute><DashboardLayout/></ProtectedRoute>,
    children: [
      {
        path: publicUrl("/themes"),
        element: <SuspenseRoute><Theme/></SuspenseRoute>,
        handle: {
          crumb: () => "Themes",
        },
      },
      {
        path: publicUrl("/settings"),
        element: <SuspenseRoute><Settings/></SuspenseRoute>,
        handle: {
          crumb: () => "Settings",
        },
      },
      {
        path: publicUrl("/tenants"),
        element: <SuspenseRoute><Tenants/></SuspenseRoute>,
        handle: {
          crumb: () => "Tenants",
        },
      },
      {
        path: publicUrl("/logs"),
        element: <SuspenseRoute><ServerLogs/></SuspenseRoute>,
        handle: {
          crumb: () => "Logs",
        },
      },
      {
        path: publicUrl("/teams"),
        handle: {
          crumb: () => "Teams",
        },
        children: [
          {
            path: "",
            element: <SuspenseRoute><Teams/></SuspenseRoute>,
          },
          {
            path: ":id",
            element: <SuspenseRoute><TeamDetails/></SuspenseRoute>,
            handle: {
              crumb: () => "Team details",
            },
          },
        ]
      },
      {
        path: publicUrl("/invoices"),
        handle: {
          crumb: () => "Invoices",
        },
        children: [
          {
            path: "",
            element: <SuspenseRoute><Invoices/></SuspenseRoute>,
          },
          {
            path: ":id",
            element: <SuspenseRoute><InvoiceItem/></SuspenseRoute>,
            handle: {
              crumb: () => "Invoice details",
            },
          },
        ]
      },
      {
        path: publicUrl("/deletion-requests"),
        element: <SuspenseRoute><PurgedAccounts/></SuspenseRoute>,
        handle: {
          crumb: () => "Deletion requests",
        },
      },
      {
        path: publicUrl("/quotas"),
        element: <SuspenseRoute><Quotes/></SuspenseRoute>,
        handle: {
          crumb: () => "Quotas",
        },
      },
      {
        path: publicUrl("/server-modes"),
        element: <SuspenseRoute><ServerModes/></SuspenseRoute>,
        handle: {
          crumb: () => "Server modes",
        },
      },
      {
        path: publicUrl("/users"),
        handle: {
          crumb: () => "Users",
        },
        children: [
          {
            path: "",
            element: <SuspenseRoute><UsersPage/></SuspenseRoute>,
          },
          {
            path: ":id",
            element: <SuspenseRoute><UserDetailsPage/></SuspenseRoute>,
            handle: {
              crumb: () => "User details",
            },
          },
        ]
      },
      {
        path: publicUrl("/resources"),
        handle: {
          crumb: () => "Resources",
        },
        children: [
          {
            path: "",
            element: <SuspenseRoute><Resources/></SuspenseRoute>,
          },
          {
            path: ":resourceId",
            element: <SuspenseRoute><ResourceDetails/></SuspenseRoute>,
            handle: {
              crumb: () => "Resource details",
            },
          },
        ]
      },
      {
        path: publicUrl("/coupons"),
        handle: {
          crumb: () => "Coupons",
        },
        children: [
          {
            path: "",
            element: <SuspenseRoute><CouponsPage/></SuspenseRoute>,
          },
          {
            path: ":couponId",
            element: <SuspenseRoute><CouponPage/></SuspenseRoute>,
            handle: {
              crumb: () => "Coupon details",
            },
          },
        ]
      },
      {
        path: publicUrl("/promos"),
        handle: {
          crumb: () => "Promos",
        },
        children: [
          {
            path: "",
            element: <SuspenseRoute><PromosPage/></SuspenseRoute>,
          },
          {
            path: ":promoId",
            element: <SuspenseRoute><PromoPage/></SuspenseRoute>,
            handle: {
              crumb: () => "Promo details",
            },
          },
        ]
      },
      {
        path: publicUrl("/billing-accounts"),

        handle: {
          crumb: () => "Billing accounts",
        },
        children: [
          {
            path: "",
            element: <SuspenseRoute><BillingSettings/></SuspenseRoute>,
          },
          {
            path: ":billingSettingId",
            element: <SuspenseRoute><BillingSetting/></SuspenseRoute>,
            handle: {
              crumb: () => "Billing account details",
            },
          },
        ]
      },
      {
        path: publicUrl("/providers"),
        element: <SuspenseRoute><ProvidersPage/></SuspenseRoute>,
        handle: {
          crumb: () => "Providers",
        },
      },
      {
        path: publicUrl("/images"),
        element: <SuspenseRoute><ImagesPage/></SuspenseRoute>,
        handle: {
          crumb: () => "Images",
        },
      },
      {
        path: publicUrl("/sizes"),
        element: <SuspenseRoute><SizesPage/></SuspenseRoute>,
        handle: {
          crumb: () => "Sizes",
        },
      },
    ],
  },
  { index: true, path: publicUrl("/"), element: <Navigate replace to={publicUrl("/users")}/> },
  { path: publicUrl("/login"), element: <SuspenseRoute><PublicRoute><Login/></PublicRoute></SuspenseRoute> },
  { path: "*", element: <NoMatch/> },
]);

const AppRouter = () => {
  return <RouterProvider router={router}/>;
};

export default AppRouter;
