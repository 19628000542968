// CORE
import { createSlice } from '@reduxjs/toolkit';
// ACTIONS
import asyncActions from './asyncActions';


const costsSlice = createSlice({
    name : 'costs',
    initialState : {
        data:{
            total:0,
            dbs:[],
            apps:[],
            vms:[],
            k8s:[]
        },
        isLoading : false
    },
    reducers : {
        clearCurrentAttestation(state) {
            state.data = {
                total:0,
                dbs:[],
                apps:[],
                vms:[]
            };
            return state;
        },
    },
    extraReducers : builder => builder
        .addCase(asyncActions.getTeamCosts.fulfilled, (state, { payload }) => {
            state.data = payload.data;
            state.isLoading = false;
        })
        .addCase(asyncActions.getTeamCosts.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(asyncActions.getTeamCosts.rejected, (state) => {
            state.isLoading = false;
        })

});

export default {
    ...costsSlice,
    asyncActions,
};
