// CORE
import { AccountBalanceWallet, Apps, Category, Password, Redeem, Tune } from "@mui/icons-material";
import React from 'react';
// CONSTANTS
import {adminRoles} from '../../../../constants/roles';
// MUI
import GroupsIcon from '@mui/icons-material/Groups';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import StorageIcon from '@mui/icons-material/Storage';
import DiscountIcon from '@mui/icons-material/Discount';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import GroupIcon from '@mui/icons-material/Group';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import ComputerIcon from '@mui/icons-material/Computer';
import AccountBalance from '@mui/icons-material/AccountBalance';
import CloudIcon from '@mui/icons-material/Cloud';
import PlaylistAddCircleIcon from '@mui/icons-material/PlaylistAddCircle';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ContrastIcon from '@mui/icons-material/Contrast';
import LineWeightIcon        from '@mui/icons-material/LineWeight';

export const menuList = [

  // ADMIN

  {
    label   : 'Accounts',
    link    : '#',
    icon    : <GroupIcon/>,
    children: [
      {
        label   : 'Tenants',
        link    : '/tenants',
        icon    : <AdminPanelSettingsIcon/>,
      },
      {
        label   : 'Teams',
        link    : '/teams',
        icon    : <GroupsIcon/>,
      },
      {
        label   : 'Users',
        link    : '/users',
        icon    : <GroupIcon/>,
      },
      {
        label   : 'Deletion Requests',
        link    : '/deletion-requests',
        icon    : <PersonRemoveIcon/>,
      },
    ],
    roles   : [adminRoles.SUPER_ADMIN],
  },
  {
    label   : 'Billing',
    link    : '#',
    icon    : <AccountBalance/>,
    children: [
      {
        label   : 'Accounts',
        link    : '/billing-accounts',
        icon    : <AccountBalanceWallet/>,
      },
      {
        label   : 'Invoices',
        link    : '/invoices',
        icon    : <MonetizationOnIcon/>,
      },
    ],
    roles   : [adminRoles.SUPER_ADMIN],
  },
  {
    label   : 'Resources',
    link    : '#',
    icon    : <Apps />,
    children: [
      {
        label   : 'Consumption',
        link    : '/resources',
        icon    : <ComputerIcon/>,
      },
      {
        label   : 'Quotas',
        link    : '/quotas',
        icon    : <CloudDoneIcon/>,
      },
      {
        label: "Sizes",
        link: "/sizes",
        icon: <LineWeightIcon />,
      },
    ],
    roles   : [adminRoles.SUPER_ADMIN],
  },
  {
    label   : 'Products',
    link    : '#',
    icon    : <Category />,
    children: [
      {
        label   : 'Settings',
        link    : '/images',
        icon    : <PlaylistAddCircleIcon/>
      },
      {
        label   : 'Providers',
        link    : '/providers',
        icon    : <CloudIcon/>,
      },
    ],
    roles   : [adminRoles.SUPER_ADMIN],
  },
  {
    label   : 'Offers',
    link    : '#',
    icon    : <Redeem/>,
    children: [
      {
        label   : 'Discounts',
        link    : '/coupons',
        icon    : <LocalOfferIcon/>,
      },
      {
        label   : 'Promo codes',
        link    : '/promos',
        icon    : <DiscountIcon/>,
      },
    ],
    roles   : [adminRoles.SUPER_ADMIN],
  },
  {
    label   : 'Logs',
    link    : '/logs',
    icon    : <MonitorHeartIcon/>,
    children: [],
    roles   : [ adminRoles.SUPER_ADMIN ],
  },
  {
    label   : 'Settings',
    link    : '#',
    icon    : <Tune/>,
    children: [
      {
        label   : 'Password',
        link    : '/settings',
        icon    : <Password/>,
      },
      {
        label   : 'Maintenance Mode',
        link    : '/server-modes',
        icon    : <StorageIcon/>,
      },
    ],
    roles   : [ adminRoles.SUPER_ADMIN ],
  },

  // TENANT

  {
    label   : 'Users',
    link    : '/users',
    icon    : <GroupIcon/>,
    children: [],
    roles   : [adminRoles.TENANT_ADMIN],
  },
  {
    label   : 'Providers',
    link    : '/providers',
    icon    : <CloudIcon/>,
    children: [],
    roles   : [ adminRoles.TENANT_ADMIN ],
  },
  {
    label   : 'Theme UI',
    link    : '/themes',
    icon    : <ContrastIcon/>,
    children: [],
    roles   : [ adminRoles.TENANT_ADMIN ],
  },
  {
    label   : 'Settings',
    link    : '#',
    icon    : <Tune/>,
    children: [
      {
        label   : 'Password',
        link    : '/settings',
        icon    : <Password/>,
      },
    ],
    roles: [ adminRoles.TENANT_ADMIN ],
  },
];

export const routeMapping = Object.freeze({
  [adminRoles.SUPER_ADMIN] : [
    '/logs',
    '/deletion-requests',
    '/users',
    '/users/:id',
    '/teams',
    '/teams/:id',
    '/resources',
    '/resources/:resourceId',
    '/billing-accounts',
    '/billing-accounts/:billingSettingId',
    '/server-modes',
    '/quotas',
    '/invoices',
    '/invoices/:id',
    '/providers',
    '/coupons',
    '/coupons/:couponId',
    '/promos',
    '/promos/:promoId',
    '/images',
    '/sizes',
    '/tenants',
    '/settings',
  ],
  [adminRoles.TENANT_ADMIN]: [
    '/users',
    '/users/:id',
    '/themes',
    '/settings',
    '/providers',
  ],
});
