// CORE
import {createAsyncThunk} from '@reduxjs/toolkit';
// SERVICES
import serverModeService from '../../services/serverModes';


export default ({
  getServerModes: createAsyncThunk('get/server-modes', serverModeService.getServerModes),
  updateMaintenance: createAsyncThunk('patch/maintenance', serverModeService.updateMaintenance),
  updateWaitingList: createAsyncThunk('patch/waitinglist', serverModeService.updateWaitingList),
});
