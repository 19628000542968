// MUI
import { createTheme } from "@mui/material";


export const muiTheme = createTheme({
    typography : {
        fontFamily : 'Proxima Nova,sans-serif',
        fontWeightRegular : 400,
        fontWeightMedium : 500,
        fontWeightBold : 700,
        htmlFontSize : 16,
    },
    components : {
        MuiCssBaseline : {
            '@global' : {
                '@font-face' : 'Proxima Nova,sans-serif',
            },
        },
    },
});