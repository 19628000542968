// AXIOS
import apiClient from "../../libs/apiClient";


const getServerModes = () => apiClient.get(`/server-modes`);

const updateMaintenance = (data) => apiClient.patch(`/server-modes/maintenance`, data);

const updateWaitingList = (data) => apiClient.patch(`/server-modes/waitinglist`, data);

export default {
  getServerModes,
  updateMaintenance,
  updateWaitingList
}
