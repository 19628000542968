export default call => {
  var values = new Map();
  return value => {
    var is;
    return values.get(value) || (
      values.set(value, is = call(value).finally(() => values.delete(value))),
      is
    )
  };
};
