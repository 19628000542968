// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';
// SERVICES
import vmService from '../../services/virtualMachine';


export default ({
    getVMs : createAsyncThunk('get/virtual-machines', async (params) => vmService.getVirtualMachines(params)),
    getVirtualMachineById : createAsyncThunk('get/vmById', async (id) => vmService.getVirtualMachineById(id)),
});
