// CORE
import {createSlice} from '@reduxjs/toolkit';
// ACTIONS
import asyncActions from './asyncActions';
import empty from '../../helpers/fp/array/empty';
import updateMany from '../../helpers/pp/array/updateMany';


const paymentSlice = createSlice({
  name: 'payment',
  initialState: {
    items     : [],
    values    : empty(),
    customer  : null,
    isLoading : false
  },
  reducers: {
    clearPayments(state) {
      state.items = [];
      return state;
    },
    clearCustomer(state) {
      state.customer = null;
      return state;
    },
  },
  // @ts-ignore
  extraReducers: builder => builder
    .addCase(asyncActions.getPaymentsMethods.fulfilled, (state, { payload }) => {
      state.items = payload.data;
      state.isLoading = false;
    })
    .addCase(asyncActions.getPaymentsMethods.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(asyncActions.getPaymentsMethods.rejected, (state) => {
      state.isLoading = false;
    })
    .addCase(asyncActions.retrieveCustomer.fulfilled, (state, {payload}) => {
      state.customer = payload.data;
      state.isLoading = false;
    })
    .addCase(asyncActions.getPaymentsByBillingSettingId.fulfilled,
      (state, { payload, meta }) => ({
      ...state,
      values: state
       .values
       .filter((paymentMethod) => paymentMethod.billingSetting !== meta.arg)
       .concat(payload.data.map((value) => ({ ...value, billingSetting: meta.arg })))
    }))
});

export const selectPaymentMethods = state => state.payment.items;
export const selectPaymentCustomer = state => state.payment.customer;

const paymentStore = Object.freeze({
  ...paymentSlice,
  asyncActions,
});

export default paymentStore;
