// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';

import couponService from '../../services/coupon';

const couponAsyncActions = Object.freeze({
  createCoupon : createAsyncThunk('create/coupon', couponService.createCoupon),
  list         : createAsyncThunk('get/coupons',   couponService.list),
  deleteCoupon : createAsyncThunk('delete/coupon', couponService.deleteCoupon),
});

export default couponAsyncActions;

