// CORE
import {createAsyncThunk} from '@reduxjs/toolkit';
// SERVICES
import httpLogs from "../../services/httpLogs";


const httpLogsAsyncActions = Object.freeze({
  getHttpLogs: createAsyncThunk('get/http-logs', httpLogs.getLogs),
});

export default httpLogsAsyncActions;

