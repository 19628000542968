import axios from "axios";

import trap      from "../../helpers/fp/decorators/trap";
import pathsJoin from "../../helpers/fp/url/pathsJoin";
import prop      from "../../helpers/fp/object/prop";

const SIZES_PATH  = "sizes";
const BASE_URL = process.env.REACT_APP_BASE_API_URL;

const getSizes    = trap(() => axios.get(pathsJoin([BASE_URL, SIZES_PATH])).then(prop("data")));
const getSizeById = trap((sizeId) => axios.get(pathsJoin([BASE_URL, SIZES_PATH, sizeId])).then(prop("data")));

const sizesService = Object.freeze({
  getSizes,
  getSizeById,
});

export default sizesService;
