// AXIOS
import apiClient from "../../libs/apiClient";

const list         = ()   => apiClient.get("/coupons");
const deleteCoupon = (id) => apiClient.delete(`/coupons/${id}`);
const createCoupon = ({
  name,
  amount_off,
  percent_off,
  duration,
  duration_in_months,
}) => apiClient.post("/coupons", {
  name,
  amount_off,
  percent_off,
  duration,
  duration_in_months
});

const couponService = Object.freeze({
  list,
  deleteCoupon,
  createCoupon,
});

export default couponService;
