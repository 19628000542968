// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';

// SERVICES
import imageService from '../../services/image';

const cloudProviderAsyncActions = Object.freeze({
  getImages  : createAsyncThunk('get/getImages', imageService.getImages),
  activate   : createAsyncThunk('post/activateImage', imageService.activate),
  deactivate : createAsyncThunk('delete/deactivateImage', imageService.deactivate),
});

export default cloudProviderAsyncActions;
