// CORE
import {createSlice} from '@reduxjs/toolkit';
// ACTIONS
import asyncActions from './asyncActions';
// CONSTANTS
import {defaultPagination} from "../../constants/pagination";

const httpLogsSlice = createSlice({
  name: 'httpLogs',
  initialState: {
    items: [],
    info: defaultPagination,
    isLoading: false
  },
  reducers: {
    clearLogs(state) {
      state.items = [];
      state.info = defaultPagination;
      state.isLoading = false;

      return state;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(asyncActions.getHttpLogs.fulfilled, (state, {payload}) => {
        const {docs, ...pagination} = payload.data;
        state.items = docs;
        state.info = {...pagination}

        state.isLoading = false;
      })
      .addCase(asyncActions.getHttpLogs.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(asyncActions.getHttpLogs.rejected, (state) => {
        state.isLoading = false;
      })
  },
});

const httpLogsStore = Object.freeze({
  ...httpLogsSlice,
  asyncActions,
});

export default httpLogsStore;
