// AXIOS
import apiClient from "../../libs/apiClient";
// HELPERS
import {objectToURL} from "../../helpers/query";


const getLogs = (params) => apiClient.get(`/http-logs?${objectToURL(params)}`);


export default {
    getLogs,
}
