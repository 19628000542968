import axios from "axios";
import trap from "../../helpers/fp/decorators/trap";
import pathsJoin from "../../helpers/fp/url/pathsJoin";
import prop from "../../helpers/fp/object/prop";
import apiClient from "../../libs/apiClient";

const API_URL                   = process.env.REACT_APP_BASE_API_URL;
const ADMIN_URL                 = process.env.REACT_APP_API_URL;
const CLOUD_PROVIDER_PATH       = "cloud-providers";
const CLOUD_PROVIDER_HOST       = pathsJoin([API_URL, CLOUD_PROVIDER_PATH]);

const getCloudProviders = trap(() => axios.get(CLOUD_PROVIDER_HOST).then(prop("data")));

const activate          = trap((cloudProviderId) => apiClient.post(pathsJoin([CLOUD_PROVIDER_PATH, cloudProviderId])));
const deactivate        = trap((cloudProviderId) => apiClient.delete(pathsJoin([CLOUD_PROVIDER_PATH, cloudProviderId])));
const hide              = trap((cloudProviderId) => apiClient.delete(pathsJoin([CLOUD_PROVIDER_PATH, cloudProviderId, '/hide'])));

const getCloudProvidersTenant = () => apiClient.get(pathsJoin([ADMIN_URL, '/providers-tenant/providers']));

const deactivateCloudTenant  = trap((cloudProviderId) => apiClient.delete(pathsJoin([ADMIN_URL, `/providers-tenant/cloud-providers/${cloudProviderId}`])));
const hideCloudTenant        = trap((cloudProviderId) => apiClient.delete(pathsJoin([ADMIN_URL, `/providers-tenant/cloud-providers/${cloudProviderId}/hide`])));
const activateCloudTenant  = trap((providerId) => apiClient.post(pathsJoin([ADMIN_URL, `/providers-tenant/cloud-providers/${providerId}`])));

const deactivateTenant  = trap((providerId) => apiClient.delete(pathsJoin([ADMIN_URL, `/providers-tenant/providers/${providerId}`])));
const activateTenant  = trap((providerId) => apiClient.post(pathsJoin([ADMIN_URL, `/providers-tenant/providers/${providerId}`])));
const hideTenant  = trap((providerId) => apiClient.delete(pathsJoin([ADMIN_URL, `/providers-tenant/providers/${providerId}/hide`])));

const cloudProviderService = Object.freeze({
  getCloudProviders,
  activate,
  deactivate,
  getCloudProvidersTenant,
  deactivateCloudTenant,
  deactivateTenant,
  activateCloudTenant,
  activateTenant,
  hide,
  hideCloudTenant,
  hideTenant,
});

export default cloudProviderService;
