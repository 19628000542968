// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';

// SERVICES
import billingSettingService from '../../services/billingSetting';

const billingSettingAsyncActions = Object.freeze({
  getBillingSettingsByUserId : createAsyncThunk('get/billingSettingsByUserId', billingSettingService.getBillingSettingsByUserId),
  getBillingSettings         : createAsyncThunk('get/billingSettings', billingSettingService.getBillingSettings),
  getBillingSettingById      : createAsyncThunk("get/billingSettingById", billingSettingService.getBillingSettingById),
  getPaymentType             : createAsyncThunk("get/paymentType", billingSettingService.getPaymentType),
  setAutomaticPaymentType    : createAsyncThunk("post/paymentType", billingSettingService.setAutomaticPaymentType),
  setManualPaymentType       : createAsyncThunk("delete/paymentTYpe", billingSettingService.setManualPaymentType),
});

export default billingSettingAsyncActions;
