// AXIOS
import compact from "../../helpers/fp/object/compact";
import apiClient from "../../libs/apiClient";

/**
  * @template {import("../../types/Promo").PromoResponse} PromoResponse
  * @returns {Promise<import("axios").AxiosResponse<PromoResponse[]>>}
  */
const list        = ()   => apiClient.get("/promos");

/** @param {string} id */
const deactivate = (id) => apiClient.delete(`/promos/${id}`);

/** @param {string} id */
const activate   = (id) => apiClient.post(`/promos/${id}`);

/**
  * @template {import("../../types/Promo").PromoResponse} PromoResponse
  * @param {import("../../types/Promo").PromoRequest} promo
  * @returns {Promise<import("axios").AxiosResponse<PromoResponse>>}
  */
const create = (promo) => apiClient.post("/promos", compact({
  ...promo,
  restrictions: compact({
    ...promo.restrictions,
    minimum_amount_currency: promo.restrictions && promo.restrictions.minimum_amount > 0 ? promo.restrictions.minimum_amount_currency : null,
  }),
}));

const couponService = Object.freeze({
  list,
  deactivate,
  activate,
  create,
});

export default couponService;
