export default (test) => (call) => {
  const values = new Map();
  return (value) => {
    let is;
    const key = test(value);
    return values.get(key) || (
      values.set(key, is = call(value).finally(() => values.delete(key))),
      is
    );
  };
};
