// CORE
import {createSlice} from '@reduxjs/toolkit';
// ACTIONS
import asyncActions from './asyncActions';
import {serverModeTypes} from "../../pages/ServerModes/constants";


const serverModeSlice = createSlice({
  name: 'serverModes',
  initialState: {
    items: [],
    isLoading: false,
    isError: false,
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(asyncActions.getServerModes.fulfilled, (state, {payload}) => {
        state.items = payload.data;
      })
      .addCase(asyncActions.getServerModes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(asyncActions.getServerModes.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(asyncActions.updateMaintenance.fulfilled, (state, {payload}) => {
        state.items = state.items.map(mode => mode.type === serverModeTypes.maintenance ? payload.data : mode);
      })
      .addCase(asyncActions.updateWaitingList.fulfilled, (state, {payload}) => {
        state.items = state.items.map(mode => mode.type === serverModeTypes.waitinglist ? payload.data : mode);
      })
  },
});

const serverModeStore = Object.freeze({
  ...serverModeSlice,
  asyncActions,
});

export default serverModeStore;
