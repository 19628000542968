export const serverModeTabs = {
  maintenance:0,
  waitingList:1
}

export const serverModeTypes = {
  maintenance: 0,
  waitinglist: 1,
};

export const serverModeStatuses = {
  on: 0,
  off: 1,
};
