import findIndexById from "../../../fp/array/findIndexById";

const updateOne = (state, next) => {
  if (state.length === 0) return [next];
  const id    = next._id;
  const index = findIndexById(id)(state);
  if (index < 0) return state.concat(next);
  if (state[index].updatedAt === next.updatedAt) return state;
  return state.slice(0, index).concat(next, state.slice(index + 1));
}

export default updateOne;
