// AXIOS
import apiClient from "../../libs/apiClient";
// HELPERS
import {objectToURL} from "../../helpers/query";

const deleteDatabase = (id) => apiClient.delete(`/databases/${id}`);

const getDatabase = (id) => apiClient.get(`/databases/${id}`);

const getDatabases = (params) => apiClient.get(`/databases?${objectToURL(params)}`);

export default {
  deleteDatabase,
  getDatabase,
  getDatabases
}
