// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';
// SERVICES

import billingSettingService from '../../services/billingSetting';

const stripeCustomersAsyncActions = Object.freeze({
  getCustomerByBillingSettingId: createAsyncThunk('get/getCustomerByBillingSettingId', billingSettingService.getCustomerByBillingSettingId),
});

export default stripeCustomersAsyncActions;
