export const defaultPagination = {
    hasNextPage: false,
    hasPrevPage:false,
    limit: 10,
    nextPage:null,
    page:1,
    pagingCounter:1,
    prevPage:null,
    totalDocs:0,
    totalPages:1
}