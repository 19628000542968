import {
  MutationCache,
  QueryClient,
} from "@tanstack/react-query";
import { toast } from "react-toastify";

export const queryClient = new QueryClient({
  mutationCache: new MutationCache({
    onError: (error, variables, context, mutation) => {
      const errorMessage= error?.response?.data?.message || error?.message || "Unknown";

      toast.error(`Something went wrong! Error: ${errorMessage}`);
    },
    onSuccess: (variables, context) => {
      toast.success(`Success!`);
    }
  })
})
