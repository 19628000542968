// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';

// SERVICES
import cloudProviderService from '../../services/cloudProvider';

const cloudProviderAsyncActions = Object.freeze({
  getCloudProviders: createAsyncThunk('get/cloudProviders', cloudProviderService.getCloudProviders),
  activate         : createAsyncThunk('post/activateCloudProvider', cloudProviderService.activate),
  deactivate       : createAsyncThunk('delete/deactivateCloudProvider', cloudProviderService.deactivate),
  hide             : createAsyncThunk('hide/hideCloudProvider', cloudProviderService.hide),
});

export default cloudProviderAsyncActions;
