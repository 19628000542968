// CORE
import {createAsyncThunk} from '@reduxjs/toolkit';
// SERVICES
import quotaService from '../../services/qouta';

const quotaAsyncActions = Object.freeze({
  getProviderQuotas: createAsyncThunk('get/providerQuotas', quotaService.getProviderQuotas),
});

export default quotaAsyncActions;
