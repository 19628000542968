// CORE
import {createAsyncThunk} from '@reduxjs/toolkit';
// SERVICES
import teamService from '../../services/team';


const teamAsyncActions = Object.freeze({
  getTeams                   : createAsyncThunk('get/teams', async (params) => teamService.getTeams(params)),
  getUserTeams               : createAsyncThunk('get/userTeams', teamService.getUserTeams),
  updateTeam                 : createAsyncThunk('put/updateTeam', teamService.updateTeam),
  getTeamDetails             : createAsyncThunk('get/teamDetails', async (params) => teamService.getTeamDetails(params)),
  getVirtualMachines         : createAsyncThunk('get/virtualMachines', async (params) => teamService.getVirtualMachines(params)),
  getDatabases               : createAsyncThunk('get/databases', async (params) => teamService.getDatabases(params)),
  getApps                    : createAsyncThunk('get/apps', async (params) => teamService.getApps(params)),
  getKubernetes              : createAsyncThunk('get/kubernetes', teamService.getKubernetes),
  getAllResources            : createAsyncThunk('get/allResources', teamService.getAllResources),
  getTeamById                : createAsyncThunk('get/getTeamById', teamService.getTeamById),
  suspend                    : createAsyncThunk('put/suspendTeam', teamService.suspend),
  reactivate                 : createAsyncThunk('put/reactivate', teamService.reactivate),
  deactivate                 : createAsyncThunk('put/deactivateTeam', teamService.deactivate),
  getTeamsByBillingSettingId : createAsyncThunk("get/getTeamsByBillingSettingId", teamService.getTeamsByBillingSettingId),
});

export default teamAsyncActions;

