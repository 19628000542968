// CORE
import { createSlice } from '@reduxjs/toolkit';

// ACTIONS
import asyncActions from './asyncActions';

// CONSTANTS
import emptyArray        from '../../helpers/fp/array/empty';
import updateManyPayload from '../../helpers/pp/array/updateManyPayload';

const updateState = (state, { payload }) => {
  const index = state.findIndex((item) => item._id === payload.data._id);
  if (index < 0) {
    state.push(payload.data);
  } else {
    state[index] = payload.data;
  }
}

const cloudProviderSlice = createSlice({
  name          : 'cloudProvider',
  initialState  : emptyArray(),
  reducers      : {},
  extraReducers : builder => {
    builder
      .addCase(asyncActions.getCloudProviders.fulfilled, updateManyPayload)
      .addCase(asyncActions.activate.fulfilled,   updateState)
      .addCase(asyncActions.deactivate.fulfilled, updateState)
      .addCase(asyncActions.hide.fulfilled, updateState)
  },
});

const cloudProviderStore = Object.freeze({
  ...cloudProviderSlice,
  asyncActions,
});

export default cloudProviderStore;
