import filter from "../../array/filter";
import second from "../../array/second";
import pipe   from "../../function/pipe";

export default pipe([
  Object.entries,
  filter(pipe([
    second,
    Boolean,
  ])),
  Object.fromEntries
]);
