// CORE
import { createSlice } from '@reduxjs/toolkit';

// ACTIONS
import asyncActions from './asyncActions';

const updateState = (state, payload, key) => {
  const index = state[key].findIndex((item) => item._id === payload.data._id);
  if (index < 0) {
    state[key].push(payload.data);
  } else {
    state[key][index] = payload.data;
  }
}

const cloudProviderTenantSlice = createSlice({
  name          : 'cloudProviderTenant',
  initialState  : {
    providers      : [],
    cloudProviders : [],
  },
  reducers      : {},
  extraReducers : builder => {
    builder
      .addCase(asyncActions.getProvidersTenant.fulfilled, (state, { payload, meta }) => {
        state.cloudProviders = payload.data.cloudProviders;
        state.providers = payload.data.providers;
      })
      .addCase(asyncActions.deactivateCloudTenant.fulfilled, (state, { payload }) => {
        updateState(state, payload, 'cloudProviders')
      })
      .addCase(asyncActions.activateCloudTenant.fulfilled, (state, { payload }) => {
        updateState(state, payload, 'cloudProviders')
      })
      .addCase(asyncActions.hideCloudTenant.fulfilled, (state, { payload }) => {
        updateState(state, payload, 'cloudProviders')
      })
      .addCase(asyncActions.deactivateTenant.fulfilled, (state, { payload }) => {
        updateState(state, payload, 'providers')
      })
      .addCase(asyncActions.hideTenant.fulfilled, (state, { payload }) => {
        updateState(state, payload, 'providers')
      })
      .addCase(asyncActions.activateTenant.fulfilled, (state, { payload }) => {
        updateState(state, payload, 'providers')
      })
  },
});

const cloudProviderTenantStore = Object.freeze({
  ...cloudProviderTenantSlice,
  asyncActions,
});

export default cloudProviderTenantStore;
