// CORE
import { createSlice } from '@reduxjs/toolkit';

// ACTIONS
import asyncActions from './asyncActions';

// CONSTANTS
import emptyArray        from '../../helpers/fp/array/empty';
import updateManyPayload from '../../helpers/pp/array/updateManyPayload';
import updateOnePayload  from '../../helpers/pp/array/updateOnePayload';

const updateState = (state, { payload }) => {
  const index = state.findIndex((item) => item._id === payload.data._id);
  if (index < 0) {
    state.push(payload.data);
  } else {
    state[index] = payload.data;
  }
}

const providerSlice = createSlice({
  name          : 'provider',
  initialState  : emptyArray(),
  reducers      : {},
  extraReducers : builder => builder
    .addCase(asyncActions.getProviders.fulfilled, updateManyPayload)
    .addCase(asyncActions.activate.fulfilled,   updateState)
    .addCase(asyncActions.deactivate.fulfilled, updateState)
    .addCase(asyncActions.hide.fulfilled, updateState)
    .addCase(asyncActions.getProviderById.fulfilled, updateOnePayload)
});

const providerStore = Object.freeze({
  ...providerSlice,
  asyncActions,
});

export default providerStore;
