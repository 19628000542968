// CORE
import {createAsyncThunk} from '@reduxjs/toolkit';
// SERVICES
import tenantsService from '../../services/tenants';

const tenantsAsyncActions = Object.freeze({
  getTenants: createAsyncThunk('get/tenants', tenantsService.getTenants),
  deleteTenant: createAsyncThunk('delete/tenant', tenantsService.deleteTenant),
});

export default tenantsAsyncActions;
