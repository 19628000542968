// CORE
import {createAsyncThunk} from '@reduxjs/toolkit';
import invoiceService from '../../services/invoce';

// SERVICES
const invoiceAsyncActions = Object.freeze({
  getInvoicesPaginate           : createAsyncThunk("get/invoicesPaginate", invoiceService.getInvoicesPaginate),
  getInvoicesByBillingSettingId : createAsyncThunk("get/getInvoicesByBillingSettingId", invoiceService.getInvoicesByBillingSettingId),
  getInvoiceById                : createAsyncThunk("get/getInvoiceById", invoiceService.getInvoiceById),
});

export default invoiceAsyncActions;
