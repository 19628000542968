// CORE
import React from 'react';
import {Navigate} from 'react-router-dom';
// HOOKS
import useProfile from '../../../hooks/profile/useProfile';
// HELPERS
import publicUrl from '../../../helpers/fp/publicUrl';
// COMPONENTS
import PageLoader from '../PageLoader';

const ProtectedRoute = ({ children }) => {
  const { admin, isLoading } = useProfile();

  if (isLoading) return <PageLoader/>;
  if (!admin) return <Navigate replace to={publicUrl('/login')}/>;


  return children;
};

export default ProtectedRoute;
