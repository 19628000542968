import axios from "axios";

import apiClient from "../../libs/apiClient";

import trap      from "../../helpers/fp/decorators/trap";
import pathsJoin from "../../helpers/fp/url/pathsJoin";
import prop      from "../../helpers/fp/object/prop";

import { IMAGES_HOST, IMAGES_PATH } from "../image";

const APP_API_URL  = process.env.REACT_APP_API_URL;
const VERSION_PATH = "versions";
const ACTIVE_PATH  = "active";
const VERSION_HOST = pathsJoin([APP_API_URL, [IMAGES_PATH, VERSION_PATH].join("-")]);

const getVersionByImageId = trap((imageId) => axios.get(pathsJoin([IMAGES_HOST, imageId, VERSION_PATH])).then(prop("data")));
const setVersion          = ({ imageId, order, name, displayName, isDisabled }) => apiClient.post(pathsJoin([IMAGES_PATH, imageId, VERSION_PATH]), { order, name, displayName, isDisabled });
const activate            = trap((imageVersionId) => apiClient.post(pathsJoin([VERSION_HOST, imageVersionId, ACTIVE_PATH])));
const deactivate          = trap((imageVersionId) => apiClient.delete(pathsJoin([VERSION_HOST, imageVersionId, ACTIVE_PATH])));
const deleteVersion       = trap((imageVersionId) => apiClient.delete(pathsJoin([VERSION_HOST, imageVersionId])));

const imageVersionService = Object.freeze({
  getVersionByImageId,
  setVersion,
  activate,
  deactivate,
  deleteVersion,
});

export default imageVersionService;
