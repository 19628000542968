// AXIOS
import apiClient from "../../libs/apiClient";

const getProviderQuotas = () => apiClient.get(`/provider-quotas`);

const quotaService = Object.freeze({
  getProviderQuotas,
});

export default quotaService;
