// AXIOS
import trap from "../../helpers/fp/decorators/trap";
import pathsJoin from "../../helpers/fp/url/pathsJoin";
import apiClient from "../../libs/apiClient";
import {BILLING_SETTING_PATH} from "../billingSetting";

export const INVOICE_PATH = "invoices";

// const getInvoicesPaginate = ({ paginationPage, sortByValue, searchString }) => apiClient.get(INVOICE_PATH, {
  //   params: {page: paginationPage, sort: sortByValue, searchString}
  // });


const getInvoicesByBillingSettingId = (billingSettingId) => apiClient.get(pathsJoin([BILLING_SETTING_PATH, billingSettingId, INVOICE_PATH]));

const getInvoicesPaginate = ({ page, limit, order, search }) => apiClient.get(pathsJoin([INVOICE_PATH, page, "page"]), {
  params: {
    limit,
    order,
    search,
  }
});

const getInvoiceCount = trap((search) => apiClient.get(pathsJoin([INVOICE_PATH, "count"]), { params: { search }}));
const getInvoiceById  = trap((invoiceId) => apiClient.get(pathsJoin([INVOICE_PATH, invoiceId])));
const createInvoice   = (invoice)   => apiClient.post(pathsJoin([INVOICE_PATH, invoice]));
const payInvoice      = (invoiceId) => apiClient.post(pathsJoin([INVOICE_PATH, invoiceId, "pay"]));
const deleteInvoice   = (invoiceId) => apiClient.delete(pathsJoin([INVOICE_PATH, invoiceId]));

const invoiceService = Object.freeze({
  getInvoicesPaginate,
  getInvoiceById,
  getInvoiceCount,
  getInvoicesByBillingSettingId,
  createInvoice,
  deleteInvoice,
  payInvoice
});

export default invoiceService;
