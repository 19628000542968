// CORE
import {createSlice} from '@reduxjs/toolkit';
// ACTIONS
import asyncActions from './asyncActions';
import empty from '../../helpers/fp/array/empty';
import updateMany from '../../helpers/pp/array/updateMany';
import updateOne from '../../helpers/pp/array/updateOne';

const invoiceSlice = createSlice({
  name: 'invoice',
  initialState: empty(),
  reducers: {},
  extraReducers: builder => builder
    .addCase(asyncActions.getInvoicesByBillingSettingId.fulfilled, (state, { payload }) => updateMany(state, payload.data))
    .addCase(asyncActions.getInvoiceById.fulfilled, (state, { payload }) => updateOne(state, payload.data))
});

const invoiceStore = Object.freeze({
  ...invoiceSlice,
  asyncActions,
});

export default invoiceStore;
