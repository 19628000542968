// CORE
import {createSlice} from '@reduxjs/toolkit';
// ACTIONS
import asyncActions from './asyncActions';
// CONSTANTS
import {reduxStatuses} from '../../constants/redux/status';

const adminSlice = createSlice({
  name         : 'admin',
  initialState : {
    current: null,
    status : reduxStatuses.IDLE,
  },
  reducers     : {
    clearCurrentAdmin(state) {
      state.current = null;
      state.status = reduxStatuses.IDLE;

      return state;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(asyncActions.getProfile.fulfilled, (state, { payload }) => {
        state.current = payload.data;
        state.status = reduxStatuses.SUCCEEDED;
      })
            .addCase(asyncActions.getProfile.pending, (state) => {
              state.status = reduxStatuses.PENDING;
            })
            .addCase(asyncActions.getProfile.rejected, (state) => {
              state.status = reduxStatuses.FAILED;
            })
    },
});

export default ({
    ...adminSlice,
    asyncActions,
});
