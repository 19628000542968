// CORE
import { createAsyncThunk } from "@reduxjs/toolkit";

// SERVICES
import sizesService from "../../services/sizes";

const imageVersionAsyncActions = Object.freeze({
  getSizes    : createAsyncThunk("get/getSizes", sizesService.getSizes),
  getSizeById : createAsyncThunk("get/getSizeById", sizesService.getSizeById),
});

export default imageVersionAsyncActions;
