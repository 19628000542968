// CORE
import { createSlice } from "@reduxjs/toolkit";

// ACTIONS
import resourcesAsyncActions from "./asyncActions";

// helpers
import empty  from "../../helpers/fp/array/empty";
import indexed_id from "../../helpers/fp/array/indexed/_id";
import uniq_id from "../../helpers/fp/array/uniq/_id";


const resourceSlice = createSlice({
  name          : "resources",
  initialState  : empty(),
  extraReducers : builder => builder
    .addCase(
      resourcesAsyncActions.getTeamResources.fulfilled,
      (state, { payload }) => {
        const current = indexed_id(state);

        const newData = payload.data.docs.filter((resource) => {
          const found = current(resource._id);
          return !found || (resource.updatedAt !== found.updatedAt);
        });

        if (newData.length > 0) return uniq_id(state.concat(newData));
        return state;
        // const newData = payload.data.docs.filter();
      }
    ),
});

const resourceStore = Object.freeze({
  ...resourceSlice,
  asyncActions: resourcesAsyncActions,
});

export default resourceStore;
