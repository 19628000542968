// CORE
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import Collapse from "@mui/material/Collapse";
import React, { useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, useMatches, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Link from '@mui/material/Link';

// MUI
import {
  Box,
  Breadcrumbs,
  Button,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText, Toolbar,
  Typography,
} from "@mui/material";
// STYLES
import "./dashBoard.scss";
import { adminRoles } from "../../../constants/roles";
// STORE
import adminStore from "../../../store/admin";
import { selectAdmin } from "../../../store/admin/selector";
// COMPONENTS
import { menuList } from "./constants";
import publicUrl from "../../../helpers/publicUrl";
import PublicLink from "../../shared/Link";
// HOOKS
import { useServerLogs } from "../../../hooks/useServerLogs/useServerLogs";
// CONSTANTS
import { jwtTokens } from "../../../constants/auth/tokens";

const NestedMenuItem = ({ item, currentPath }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => setOpen(value => !value);

  const hasNestedMenuItems = !!item.children?.length;

  useEffect(() => {
    if (hasNestedMenuItems && item.children.some(child => child.link === currentPath)) {
      setOpen(true);
    }
  }, []);

  if (hasNestedMenuItems) {
    return (
      <>
        <ListItemButton onClick={handleClick}>
          <ListItemIcon>
            {item.icon}
          </ListItemIcon>
          <ListItemText primary={item.label}/>
          {hasNestedMenuItems ? (open ? <ExpandLess/> : <ExpandMore/>) : null}
        </ListItemButton>

        <Collapse in={open} timeout="auto">
          {item.children.map(child => (
            <List key={child.label} disablePadding>
              <ListItemButton sx={{ pl: 4 }}
                              component={PublicLink}
                              to={child.link}
                              selected={child.link === currentPath}
              >
                <ListItemIcon>
                  {child.icon}
                </ListItemIcon>
                <ListItemText primary={child.label}/>
              </ListItemButton>
            </List>
          ))}
        </Collapse>
      </>
    );
  }

  return (
    <ListItemButton
      component={PublicLink}
      to={item.link}
      selected={item.link === currentPath}>
      <ListItemIcon>
        {item.icon}
      </ListItemIcon>
      <ListItemText primary={item.label}/>
    </ListItemButton>
  );
};

const DashboardLayout = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const admin = useSelector(selectAdmin);

  // TODO: move it to corresponding page?
  useServerLogs();

  const matches = useMatches();

  const breadcrumbs = matches
    .filter((match) => Boolean(match.handle?.crumb))
    .map((match) => ({
        pathname: match.pathname,
        crumb: match.handle.crumb(match.data),
      }
    ));

  const menus = useMemo(
    () => menuList.filter(link => link.roles.includes(admin.role)),
    [admin.role]);

  const handleLogout = () => {
    localStorage.removeItem(jwtTokens.ACCESS_TOKEN);
    dispatch(adminStore.actions.clearCurrentAdmin());
    navigate(publicUrl("/login"));
  };

  return (
    <Box display="flex" width="100%" height="100%">
      <Box
        sx={{
          width: "250px",
          flexDirection: "column",
          display: "flex",
          height: "100vh",
          borderRight: "1px solid #dfdfdf",
          backgroundColor: "white",
          position: "fixed",
          flexShrink: 0,
        }}
      >
        <Toolbar/>
        <Divider/>

        <List
          sx={{
            width: "100%",
            overflowY: "auto"
          }}
          component="nav"
        >
          {menus.map((item) => {
            return (
              <NestedMenuItem item={item} currentPath={location.pathname}/>
            );
          })}
        </List>

        <Divider/>
        <Box padding="20px" display="flex" justifyContent="center">
          <Button onClick={handleLogout} variant="contained">Log out</Button>
        </Box>
      </Box>

      <Box sx={{
        marginLeft: "250px",
        flexGrow: 1,
        overflowX: "auto",
        height: "100%"
      }}>
        {breadcrumbs.length > 1 && (
          <>
            <Box sx={{ padding: "0.5rem 1rem" }}>
              <Breadcrumbs aria-label="breadcrumb">
                {
                  breadcrumbs.map(breadcrumb => (
                    breadcrumb.pathname === location.pathname ? (
                      <Typography sx={{ color: 'text.primary' }}>{breadcrumb.crumb}</Typography>
                    ) : (
                      <Link component={PublicLink}
                            key={breadcrumb.pathname}
                            to={breadcrumb.pathname}
                            underline="hover"
                            color="inherit"
                      >

                        {breadcrumb.crumb}
                      </Link>
                    )
                  ))
                }
              </Breadcrumbs>
            </Box>
            <Divider/>
          </>
        )}
        <Outlet/>
      </Box>
    </Box>
  );
};

export default DashboardLayout;
