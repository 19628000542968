// AXIOS
import apiClient from "../../libs/apiClient";
// HELPERS
import {objectToURL} from "../../helpers/query";

const getActivitiesByResource = (params) => apiClient.get(`/activities?${objectToURL(params)}`);

export default {
  getActivitiesByResource
}
