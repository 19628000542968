// CORE
import {createAsyncThunk} from '@reduxjs/toolkit';

// SERVICES
import teamQuotaService from '../../services/teamQuota';

const teamQuotaAsyncActions = Object.freeze({
  save      : createAsyncThunk('put/saveTeamQuota',       teamQuotaService.save),
  getByTeam : createAsyncThunk('get/getTeamQuotasByTeam', teamQuotaService.getByTeam),
});

export default teamQuotaAsyncActions;
