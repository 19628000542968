// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';
// SERVICES
import teamService from '../../services/team';

const payedAsyncActions = Object.freeze({
  getTeamPayedByTeamId: createAsyncThunk('get/getTeamPayedByTeamId', teamService.getTeamPayedByTeamId),
});

export default payedAsyncActions;
