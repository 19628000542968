// AXIOS
import apiClient from '../../libs/apiClient';

const FORM_HEADERS = { headers: { 'Content-Type': 'multipart/form-data' } };

const getTheme = () => apiClient.get('/themes/current');

const updateTheme = (id, styles) => apiClient.put(`/themes/${id}`, styles);

const updateIcons = (id, formData) => apiClient.post(`/themes/${id}/icons`, formData,FORM_HEADERS);

const themeService = Object.freeze({
  getTheme,
  updateTheme,
  updateIcons
});

export default themeService;
