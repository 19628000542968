// CORE
import React from "react";
// COMPONENTS
import PageLoader from "../PageLoader";


const SuspenseRoute = ({ children }) => (
        <React.Suspense fallback={<PageLoader/>}>
            {children}
        </React.Suspense>
    )

export default SuspenseRoute;