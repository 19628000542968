// AXIOS
import apiClient from "../../libs/apiClient";
// HELPERS
import {objectToURL} from "../../helpers/query";

const deleteCluster = (id) => apiClient.delete(`/clusters/${id}`);

const getCluster = (id) => apiClient.get(`/clusters/${id}`);

const getClusters = (params) => apiClient.get(`/clusters?${objectToURL(params)}`);

export default {
  deleteCluster,
  getCluster,
  getClusters
}
