// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';

import promoService from '../../services/promo';

const promoAsyncActions = Object.freeze({
  list       : createAsyncThunk('get/promos',       promoService.list),
  create     : createAsyncThunk('create/promo',     promoService.create),
  deactivate : createAsyncThunk('deactivate/promo', promoService.deactivate),
  activate   : createAsyncThunk('activate/promo',   promoService.activate),
});

export default promoAsyncActions;

