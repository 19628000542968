// CORE
import {createSlice} from '@reduxjs/toolkit';
// ACTIONS
import asyncActions from './asyncActions';
// CONSTANTS
import {defaultPagination} from "../../constants/pagination";


const timeActivitySlice = createSlice({
  name: 'timeActivity',
  initialState: {
    items: [],
    info: defaultPagination,
    isLoading: false,
    isError: false,
  },
  reducers: {
    clearActivities(state) {
      state.items = [];
      state.info = defaultPagination;

      return state;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(asyncActions.getTimeActivitiesByResource.fulfilled, (state, {payload}) => {
        const {docs, ...pagination} = payload.data;
        state.items = docs;
        state.info = {...pagination}

        state.isLoading = false;
      })
      .addCase(asyncActions.getTimeActivitiesByResource.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(asyncActions.getTimeActivitiesByResource.rejected, (state) => {
        state.isLoading = false;
      })
  },
});

const timeActivityStore = Object.freeze({
  ...timeActivitySlice,
  asyncActions,
});

export default timeActivityStore;
