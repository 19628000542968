import apiClient from "../../libs/apiClient";
import {objectToURL} from "../../helpers/query";
import trapBy from "../../helpers/fp/decorators/trap/by";
import pathsJoin from "../../helpers/fp/url/pathsJoin";
import trap from "../../helpers/fp/decorators/trap";
import {BILLING_SETTING_PATH} from "../billingSetting";

const TEAM_PATH = "teams";

const getTeams = (params) => apiClient.get(`/teams?${objectToURL(params)}`);

const getTeamDetails = ({id}) => apiClient.get(`/teams/${id}`);


const updateTeam = ({id, data}) => apiClient.put(`/teams/${id}`, data);

const getUserTeams = ({id, ...params}) => apiClient.get(`/users/${id}/teams?${objectToURL(params)}`);

const getVirtualMachines = ({id, query}) => apiClient.get(`/teams/${id}/virtual-machines?${objectToURL(query)}`);

const getDatabases = ({id, query}) => apiClient.get(`/teams/${id}/databases?${objectToURL(query)}`);

const getApps = ({id, query}) => apiClient.get(`/teams/${id}/apps?${objectToURL(query)}`);

const getAllResources =
  trapBy(({ id, query }) => query.page + id + query.limit)
  (({ id, query }) => apiClient.get(`/teams/${id}/resources?${objectToURL(query)}`))
;

const getKubernetes = ({id, query}) => apiClient.get(`/teams/${id}/clusters?${objectToURL(query)}`);

const getTeamCosts = ({id}) => apiClient.get(`/teams/${id}/costs`);
const getTeamPayedByTeamId = (teamId) => apiClient.get(`/teams/${teamId}/payed`);

const getTeamById = trap((teamId) => apiClient.get(pathsJoin([TEAM_PATH, teamId])));
const suspend     = trap((teamId) => apiClient.get(`/teams/${teamId}/suspend`));
const deactivate  = trap((teamId) => apiClient.get(`/teams/${teamId}/deactivate`));
const reactivate  = trap((teamId) => apiClient.get(`/teams/${teamId}/reactivate`));

const paginate    = ({ page, search, order, limit }) => apiClient.get(pathsJoin([TEAM_PATH, page, "page"]), { params: {
  search,
  order,
  limit,
}});

const count = (search) => apiClient.get(pathsJoin([TEAM_PATH, "count"]), { params: { search }});

const getTeamsByBillingSettingId = trap((billingSettingId) => apiClient.get(pathsJoin([BILLING_SETTING_PATH, billingSettingId, TEAM_PATH])));

const teamService = Object.freeze({
  getTeams,
  getTeamDetails,
  getTeamById,
  getVirtualMachines,
  getDatabases,
  getApps,
  getKubernetes,
  getTeamCosts,
  getAllResources,
  getUserTeams,
  updateTeam,
  suspend,
  deactivate,
  reactivate,
  getTeamPayedByTeamId,

  paginate,
  count,
  getTeamsByBillingSettingId,
});

export default teamService;
