// CORE
import {createSlice} from '@reduxjs/toolkit';
// ACTIONS
import asyncActions from './asyncActions';
// CONSTANTS

const themeSlice = createSlice({
  name         : 'theme',
  initialState : {
    current  : null,
    isLoading: false,
  },
  reducers     : {
    clearTheme(state) {
      state.current = null;

      return state;
    },
    updateTheme(state, { payload }) {
      state.current = { ...state.current, ...payload };

      return state;
    },
  },
  extraReducers: builder => {
    builder.addCase(asyncActions.getCurrentTheme.fulfilled,
      (state, { payload }) => {
        state.current = payload.data;
        state.isLoading = false;
        return state;
      }).addCase(asyncActions.getCurrentTheme.pending, (state) => {
      state.isLoading = true;
    }).addCase(asyncActions.getCurrentTheme.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

const themeStore = Object.freeze({
  ...themeSlice,
  asyncActions,
});

export default themeStore;
