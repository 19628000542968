import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from 'react';
import {selectAdmin, selectAdminStatus} from '../../store/admin/selector';
import adminStore from '../../store/admin';
import {reduxStatuses} from '../../constants/redux/status';

const loadMapping = {
  [reduxStatuses.IDLE]     : true,
  [reduxStatuses.PENDING]  : true,
  [reduxStatuses.FAILED]   : false,
  [reduxStatuses.SUCCEEDED]: false,
};

export const useProfile = () => {
  const admin = useSelector(selectAdmin);
  const status = useSelector(selectAdminStatus);
  const dispatch = useDispatch();
  const isLoading = loadMapping[status];

  useEffect(() => {
    if (!admin) dispatch(adminStore.asyncActions.getProfile());
  }, [ admin ]);

  return { admin, isLoading };
};

export default useProfile;
