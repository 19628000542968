import findById from "../../../fp/array/findById";

const updateMany = (state, values) => {
  if (state.length === 0) return values.length ? values : state;

  const create = values.reduce((create, next) => {
    const id = next._id;
    const current = findById(id)(state);
    if (current && current.updatedAt === next.updatedAt) return create;
    create.set(id, next);
    return create;
  }, new Map());

  return create.size
    ? state.filter((value) => !create.has(value._id)).concat(Array.from(create.values()))
    : state
  ;
};

export default updateMany;
