// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';
// SERVICES
import userService from '../../services/user';

import billingSettingService from '../../services/billingSetting';

const paymentMethodAsyncActions = Object.freeze({
  getPaymentsByBillingSettingId : createAsyncThunk('get/paymentMethodsByBillingSettingId', billingSettingService.getPaymentMethodsByBillingSettingId),
  getPaymentsMethods            : createAsyncThunk('get/paymentMethods', userService.getPaymentsMethods),
  retrieveCustomer              : createAsyncThunk('retrieve/customer', userService.retrieveCustomer),
});

export default paymentMethodAsyncActions;
