// CORE
import React from "react";
// MUI
import { CircularProgress, Box } from '@mui/material';


const PageLoader = () => (
    <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
        <CircularProgress/>
    </Box>
);

export default PageLoader;
