// CORE
import { createSlice } from '@reduxjs/toolkit';
// ACTIONS
import asyncActions from './asyncActions';
// CONSTANTS
import { defaultPagination } from "../../constants/pagination";


const databaseSlice = createSlice({
    name: 'database',
    initialState: {
      items     : [],
      info      : defaultPagination,
      current   : null,
      isLoading : false,
      isError   : false,
    },
    reducers: {
        clearDatabases(state) {
            state.items = [];
            state.info = defaultPagination;

            return state;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(asyncActions.getDatabases.fulfilled, (state, {payload}) => {
                const {docs, ...pagination} = payload.data;
                state.items = docs;
                state.info = {...pagination}

                state.isLoading = false;
            })
            .addCase(asyncActions.getDatabases.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(asyncActions.getDatabases.rejected, (state) => {
                state.isLoading = false;
            })
    },
});

const databaseStore = Object.freeze({
  ...databaseSlice,
  asyncActions,
});

export default databaseStore;
