// CORE
import { createSlice } from '@reduxjs/toolkit';

// ACTIONS
import asyncActions from './asyncActions';

// CONSTANTS
import emptyArray        from '../../helpers/fp/array/empty';
import updateManyPayload from '../../helpers/pp/array/updateManyPayload';
import findIndexById     from '../../helpers/fp/array/findIndexById';

const setStatus = (isDisabled) => (state, { meta }) => {
  const imageVersionId = meta.arg;
  const imageVersionIndex = findIndexById(imageVersionId)(state);
  return imageVersionIndex < 0 || isDisabled === state[imageVersionIndex].isDisabled
    ? state
    : state.slice(0, imageVersionIndex).concat({
      ...state[imageVersionIndex],
      isDisabled,
    }, state.slice(imageVersionIndex + 1))
};

const actiavateById  = setStatus(false);
const deactivateById = setStatus(true);

const updated = new Map();

const imageVersionSlice = createSlice({
  name          : 'imagesVersions',
  initialState  : emptyArray(),
  reducers      : {},
  extraReducers : builder => {
    builder
      .addCase(asyncActions.getVersionByImageId.fulfilled, updateManyPayload)
      .addCase(asyncActions.activate.pending,     actiavateById)
      .addCase(asyncActions.activate.fulfilled,   actiavateById)
      .addCase(asyncActions.deactivate.pending,   deactivateById)
      .addCase(asyncActions.deactivate.fulfilled, deactivateById)
      .addCase(asyncActions.setVersion.pending, (state, { meta }) => {
        console.dir({ meta }, { depth: 10 })
        const { imageId, name, displayName } = meta.arg;
        const imageVersionIndex = state.findIndex((imageVersion) => imageVersion.image === imageId && imageVersion.name === name);
        updated.set(imageId + name, imageVersionIndex);
        const newImageVersion = ({
          image: imageId,
          name,
          displayName: displayName || name,
          isDisabled: state[imageVersionIndex]?.isDisabled || false,
        });

        return imageVersionIndex < 0
          ? state.concat(newImageVersion)
          : state.slice(0, imageVersionIndex).concat(newImageVersion, state.slice(imageVersionIndex + 1))
      })
      .addCase(asyncActions.setVersion.fulfilled, (state, { payload, meta }) => {
        const { imageId, name } = meta.arg;
        updated.delete(imageId + name);
        const imageVersionIndex = state.findIndex((imageVersion) => imageVersion.image === imageId && imageVersion.name === name);
        return imageVersionIndex < 0
          ? state.concat(payload.data)
          : state.slice(0, imageVersionIndex).concat(payload.data, state.slice(imageVersionIndex + 1))
      })
      .addCase(asyncActions.setVersion.rejected, (state, { meta }) => {
         const { imageId, name } = meta.arg;
         const imageVersion = updated.get(imageId + name);
         updated.delete(imageId + name);
         return imageVersion
           ? state.filter((imageVersionStore) => !(imageVersionStore.image === imageId && imageVersionStore.name === name))
           : state.map((imageVersionStore) => imageVersionStore.image === imageId && imageVersionStore.name === name
              ? imageVersion
              : imageVersionStore
            )
      })
      .addCase(asyncActions.deleteVersion.pending, (state, { meta }) => {
        const imageVersionId = meta.arg;
        const imageVersionIndex = findIndexById(imageVersionId)(state);
        return imageVersionIndex < 0
          ? state
          : state.slice(0, imageVersionIndex).concat(state.slice(imageVersionIndex + 1))
      })
      .addCase(asyncActions.deleteVersion.fulfilled, (state, { meta }) => {
        const imageVersionId = meta.arg;
        const imageVersionIndex = findIndexById(imageVersionId)(state);
        return imageVersionIndex < 0
          ? state
          : state.slice(0, imageVersionIndex).concat(state.slice(imageVersionIndex + 1))
      })
  },
});

const imageVersionStore = Object.freeze({
  ...imageVersionSlice,
  asyncActions,
});

export default imageVersionStore;
