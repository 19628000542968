// CORE
import {createSlice} from '@reduxjs/toolkit';
// ACTIONS
import asyncActions from './asyncActions';
// CONSTANTS
import {defaultPagination} from '../../constants/pagination';

const tenantSlice = createSlice({
  name         : 'tenants',
  initialState : {
    items    : [],
    info     : defaultPagination,
    isLoading: false,
  },
  reducers     : {
    clearTenants(state) {
      state.items = [];
      state.info = defaultPagination;

      return state;
    },
    addTenant(state, {payload}) {
      const tenants = state.items.slice(0, state.info.limit - 1);
      state.items = [payload, ...tenants];
      state.info.totalDocs = state.info.totalDocs + 1;

      return state;
    },
  },
  extraReducers: builder => {
    builder.addCase(asyncActions.getTenants.fulfilled, (state, { payload }) => {
      const { docs, ...pagination } = payload.data;
      state.items = docs;
      state.info = { ...pagination };
      state.isLoading = false;
      return state;
    }).addCase(asyncActions.getTenants.pending, (state) => {
      state.isLoading = true;
    }).addCase(asyncActions.getTenants.rejected, (state) => {
      state.isLoading = false;
    }).addCase(asyncActions.deleteTenant.fulfilled, (state, { meta }) => {
      state.items = state.items.filter(tenant => tenant.id !== meta.arg);
      return state;
    })
  },
});

const tenantStore = Object.freeze({
  ...tenantSlice,
  asyncActions,
});

export default tenantStore;
