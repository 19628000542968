// CORE
import {createAsyncThunk} from '@reduxjs/toolkit';
// SERVICES
import themeService from '../../services/theme';

const themeAsyncActions = Object.freeze({
  getCurrentTheme: createAsyncThunk('get/theme', themeService.getTheme),
});

export default themeAsyncActions;
