import axios from 'axios';
// CONSTANTS
import {jwtTokens} from '../constants/auth/tokens';
import trap from '../helpers/fp/decorators/trap';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL, headers: {
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.request.use((config) => {
  const token = localStorage.getItem(jwtTokens.ACCESS_TOKEN);

  if (token) config.headers.Authorization = `Bearer ${token}`;

    return config;
});

apiClient.interceptors.response.use((response) => response, (error) => {
    if (error?.response?.status === 401) {
      localStorage.removeItem(jwtTokens.ACCESS_TOKEN);
    }

    return Promise.reject(error);
});

export const getTrap = trap((url) => apiClient.get(url));

export default apiClient;
