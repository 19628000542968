// CORE
import { createSlice } from '@reduxjs/toolkit';
// ACTIONS
import asyncActions from './asyncActions';
import empty from '../../helpers/fp/array/empty';

const promoSlice = createSlice({
  name          : 'promo',
  initialState  : empty(),
  extraReducers : builder => {
    builder
      .addCase(asyncActions.list.fulfilled, (state, { payload }) => payload.data)
      .addCase(asyncActions.create.fulfilled, (state, { payload }) => state.concat(payload.data))
      .addCase(asyncActions.deactivate.fulfilled, (state, { payload }) => state.map(promo => promo.id === payload.data.id ? payload.data : promo))
      .addCase(asyncActions.activate.fulfilled, (state, { payload }) => state.map(promo => promo.id === payload.data.id ? payload.data : promo))
  },
});

const promoStore = Object.freeze({
  ...promoSlice,
  asyncActions,
});

export default promoStore;
