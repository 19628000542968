// CORE
import {createSlice} from '@reduxjs/toolkit';


const logSlice = createSlice({
  name: 'logs',
  initialState: {
    items: ['Server Logs: \n']
  },
  reducers: {
    setLogs(state, {payload}) {
      state.items = [...state.items, payload];
      return state;
    },
    clearLogs(state) {
      state.items = ['Server Logs: \n'];

      return state;
    },
  },
});

export default ({
    ...logSlice,
});
