// AXIOS
import apiClient from '../../libs/apiClient';

const getTenants = (params) => apiClient.get('/tenants', { params });

const createTenant = (body) => apiClient.post('/auth/register', body);

const deleteTenant = (id) => apiClient.delete(`/tenants/${id}`);

const tenantsService = Object.freeze({
  getTenants,
  createTenant,
  deleteTenant,
});

export default tenantsService;
