// CORE
import { createSlice } from '@reduxjs/toolkit';
// ACTIONS
import asyncActions from './asyncActions';

import empty from '../../helpers/fp/array/empty';
import assoc from '../../helpers/fp/object/assoc';

const assocTeamId = assoc('teamId');

const payedSlice = createSlice({
  name : 'costs',
  initialState : empty(),
  reducers : {},
  extraReducers : (builder) => builder
  .addCase(asyncActions.getTeamPayedByTeamId.fulfilled, (state, { meta, payload }) => state
    .filter(({ teamId }) => teamId !== meta.arg)
    .concat(assocTeamId(meta.arg)(payload.data))
  )
});

const payedStore = Object.freeze({
  ...payedSlice,
  asyncActions,
});

export default payedStore;
