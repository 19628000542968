// CORE
import { createSlice } from '@reduxjs/toolkit';

// ACTIONS
import asyncActions from './asyncActions.js';

// helpers
import empty from '../../helpers/fp/array/empty';
import updateMany from '../../helpers/pp/array/updateMany';
import updateOne from '../../helpers/pp/array/updateOne/index.js';

const teamQuotaSlice = createSlice({
  name          : 'teamQuota',
  initialState  : empty(),
  reducers      : {},
  extraReducers : builder => builder
    .addCase(asyncActions.getByTeam.fulfilled, (state, { payload }) => updateMany(state, payload.data))
    .addCase(asyncActions.save.pending, (state, { meta }) => {
      const { image, team } = meta.arg;
      const index = state.findIndex(teamQuota => teamQuota.image === image && teamQuota.team === team);
      if (index < 0) return state;
      return state.slice(0, index).concat(({ ...state[index], counts: meta.arg.counts }), state.slice(index + 1));
    })
    .addCase(asyncActions.save.fulfilled, (state, { payload }) => updateOne(state, payload.data))
});

const teamQuotaStore = Object.freeze({
  ...teamQuotaSlice,
  asyncActions,
});

export default teamQuotaStore;
