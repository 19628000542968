// CORE
import {createSlice} from '@reduxjs/toolkit';
// ACTIONS
import asyncActions from './asyncActions';
import empty from '../../helpers/fp/array/empty';
import findIndexByPropIs from '../../helpers/fp/array/findIndexByPropIs';
import eq from '../../helpers/pp/object/eq';

const findIndexByBillingSettingId = findIndexByPropIs("billingSettingId");

const stripeCustomerSlice = createSlice({
  name: 'stripeCustomers',
  initialState: empty(),
  reducers: {},
  // @ts-ignore
  extraReducers: builder => builder
    .addCase(asyncActions.getCustomerByBillingSettingId.fulfilled, (state, { payload, meta }) => {
        const billingSettingId = meta.arg;
        const index = findIndexByBillingSettingId(billingSettingId)(payload.data);
        const customer = ({ ...payload.data, billingSettingId });
        if (index < 0) return state.concat(customer);
        const currentCustomer = state[index];
        if (eq(currentCustomer, customer)) return state;
        return state.slice(0, index).concat(state.slice(index + 1), customer);
      }
    )
});

export const selectPaymentMethods = state => state.payment.items;
export const selectPaymentCustomer = state => state.payment.customer;

const stripeCustomerStore = Object.freeze({
  ...stripeCustomerSlice,
  asyncActions,
});

export default stripeCustomerStore;
