// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';
import teamService from '../../services/team';

const resourcesAsyncActions = Object.freeze({
  getTeamResources: createAsyncThunk('get/teamResources', teamService.getAllResources)
});

export default resourcesAsyncActions;

