// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';

// SERVICES
import cloudProviderService from '../../services/cloudProvider';

const cloudProviderAsyncActions = Object.freeze({
  getProvidersTenant    : createAsyncThunk('get/providersTenant', cloudProviderService.getCloudProvidersTenant),
  deactivateCloudTenant : createAsyncThunk('delete/deactivateCloudProviderTenant', cloudProviderService.deactivateCloudTenant),
  deactivateTenant : createAsyncThunk('delete/deactivateProviderTenant', cloudProviderService.deactivateTenant),
  activateTenant : createAsyncThunk('post/activateProviderTenant', cloudProviderService.activateTenant),
  deactivate        : createAsyncThunk('delete/deactivateCloudProvider', cloudProviderService.deactivate),
  activateCloudTenant : createAsyncThunk('post/activateCloudProviderTenant', cloudProviderService.activateCloudTenant),
  hideCloudTenant : createAsyncThunk('delete/hideCloudProviderTenant', cloudProviderService.hideCloudTenant),
  hideTenant : createAsyncThunk('delete/hideProviderTenant', cloudProviderService.hideTenant),
});

export default cloudProviderAsyncActions;
