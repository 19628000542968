// CORE
import { createSlice } from '@reduxjs/toolkit';
// ACTIONS
import asyncActions from './asyncActions';
import empty from '../../helpers/fp/array/empty';

const couponSlice = createSlice({
  name          : 'coupon',
  initialState  : empty(),
  extraReducers : builder => {
    builder
      .addCase(asyncActions.list.fulfilled, (state, { payload }) => payload.data)
      .addCase(asyncActions.deleteCoupon.fulfilled, (state, { meta }) => state.filter(coupon => coupon.id !== meta.arg))
      .addCase(asyncActions.createCoupon.fulfilled, (state, { payload }) => [...state, payload.data])
  },
});

const couponStore = Object.freeze({
  ...couponSlice,
  asyncActions,
});

export default couponStore;
