// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';

// SERVICES
import publicConfigService from '../../services/publicConfig';

const pubclicConsfigAsyncActions = Object.freeze({
  getPublicConfig: createAsyncThunk('get/publicConfig', publicConfigService.getPublicConfig),
});

export default pubclicConsfigAsyncActions;
