// AXIOS
import apiClient from "../../libs/apiClient";
// HELPERS
import {objectToURL} from "../../helpers/query";

const getTimeActivitiesByResource = ({id,...params}) => apiClient.get(`/resources/${id}/activities?${objectToURL(params)}`);

export default {
  getTimeActivitiesByResource
}
