/**
  * @function
  * @name memoizeWeak
  */
export default (constructor) => (call) => {
  const values = new constructor();
  return (value) => {
    if (values.has(value)) return values.get(value);
    const result = call(value);
    values.set(value, result);
    return result;
  };
};
