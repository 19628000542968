// CORE
import { configureStore } from '@reduxjs/toolkit';
// REDUCERS
import { rootReducer } from "./reducers";


export default configureStore({
    reducer : rootReducer,
    middleware : (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck : false }),
    devTools : process.env.NODE_ENV === 'development'
});
