import memoize from "../../decorators/memoize";
import memoizeWeak from "../../decorators/memoize/weak";
import indexedIndex from "../indexedIndex";

export default memoize((key) => {
  const findIndex = indexedIndex(key);
  return memoizeWeak((values) => {
    const index = findIndex(values);
    return (value) => values[index(value)];
  });
});
