// AXIOS
import apiClient from "../../libs/apiClient";
import {objectToURL} from "../../helpers/query";

const deleteApp = (id) => apiClient.delete(`/apps/${id}`);

const getApp = (id) => apiClient.get(`/apps/${id}`);

const getApps = (params) => apiClient.get(`/apps?${objectToURL(params)}`);

export default {
  deleteApp,
  getApp,
  getApps
}
