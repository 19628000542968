import axios from "axios";
import trap from "../../helpers/fp/decorators/trap";
import pathsJoin from "../../helpers/fp/url/pathsJoin";
import prop from "../../helpers/fp/object/prop";

const API_URL         = process.env.REACT_APP_BASE_API_URL;
const CONFIG_PATH     = "config";
const CONFIG_URL      = pathsJoin([API_URL, CONFIG_PATH]);
const getPublicConfig = trap(() => axios.get(CONFIG_URL).then(prop("data")));

const publicConfigService = Object.freeze({
  getPublicConfig
});

export default publicConfigService;
