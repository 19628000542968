import memoize from "../../decorators/memoize";
import memoizeWeak from "../../decorators/memoize/weak";

var find = (map, key, values, value) => {
  var length = values.length;
  var index  = map.size;
  if (index < length) {
    var set = values[index];
    var is  = set[key];
    map.set(is, index);
    if (is === value) return index;
    while (++index < length) {
      set = values[index];
      is  = set[key];
      map.set(is, index);
      if (is === value) return index;
    }
  }
  return -1;
}

export default memoize((key) => memoizeWeak((values) => {
  var map = new Map();
  return (value) => map.has(value) ? map.get(value) : (find(map, key, values, value));
}));
