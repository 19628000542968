// CORE
import {createAsyncThunk} from '@reduxjs/toolkit';

// SERVICES
import defaultQutasService from '../../services/defaultQuota';

const defaultQuotaAsyncActions = Object.freeze({
  getDefaultQuotas : createAsyncThunk('get/defaultQuotas', defaultQutasService.getDefaultQuotas),
  saveDefaultQuota : createAsyncThunk('put/defaultQuota', defaultQutasService.saveDefaultQuota),
});

export default defaultQuotaAsyncActions;
