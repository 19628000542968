// CORE
import {createSlice} from '@reduxjs/toolkit';
// ACTIONS
import asyncActions from './asyncActions';
// CONSTANTS
import {defaultPagination} from "../../constants/pagination";
import empty from '../../helpers/fp/array/empty';
import updateMany from '../../helpers/pp/array/updateMany';
import updateOne from '../../helpers/pp/array/updateOne';
import findById from '../../helpers/fp/array/findById';

const adminSlice = createSlice({
  name: 'team',
  initialState: {
    items   : [],
    values  : empty(),
    info    : defaultPagination,
    current : null,
    allResources: {
      calculatedPricing: '\t—',
      items: [],
      info: {
        ...defaultPagination,
        limit: 5
      }
    },
    virtualMachines: {
      calculatedPricing: '\t—',
      items: [],
      info: {
        ...defaultPagination,
        limit: 5
      }
    },
    kubernetes: {
      calculatedPricing: '\t—',
      items: [],
      info: {
        ...defaultPagination,
        limit: 5
      }
    },
    databases: {
      calculatedPricing: '\t—',
      items: [],
      info: {
        ...defaultPagination,
        limit: 5
      }
    },
    apps: {
      calculatedPricing: '\t—',
      items: [],
      info: {
        ...defaultPagination,
        limit: 5
      }
    },
    isLoading: false
  },
    reducers: {
        clearTeams(state) {
            state.items = [];
            state.info = defaultPagination;

            return state;
        },
        clearTeam(state) {
            state.current = null;
            state.virtualMachines = {
                items: [],
                info: {
                    ...defaultPagination,
                    limit: 5
                }
            }

            return state;
        }
    },
    extraReducers: builder => {
      // @ts-ignore
      builder
        .addCase(asyncActions.getTeams.fulfilled, (state, {payload}) => {
          const {docs, ...pagination} = payload.data;
          state.items = docs;
          state.info = {...pagination}
          state.isLoading = false;
          state.values = updateMany(state.values, docs);
          return state;
        })
        .addCase(asyncActions.getTeams.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(asyncActions.getTeams.rejected, (state) => {
          state.isLoading = false;
        })
        .addCase(asyncActions.getTeamById.fulfilled, (state, { payload }) => ({
          ...state,
          values: updateOne(state.values, payload.data)
        }))
        .addCase(asyncActions.getUserTeams.fulfilled, (state, {payload}) => {
          const {docs, ...pagination} = payload.data;
          state.items = docs;
          state.values = updateMany(state.values, docs);
          state.info = {...pagination}
          state.isLoading = false;
          return state;
        })
        .addCase(asyncActions.getUserTeams.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(asyncActions.getUserTeams.rejected, (state) => {
          state.isLoading = false;
        })
        .addCase(asyncActions.getTeamDetails.fulfilled, (state, {payload}) => {
          state.current = payload.data;
          state.values = updateOne(state.values, payload.data);
          state.isLoading = false;
        })
        .addCase(asyncActions.getTeamDetails.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(asyncActions.getTeamDetails.rejected, (state) => {
          state.isLoading = false;
        })
        .addCase(asyncActions.getVirtualMachines.fulfilled, (state, {payload}) => {
          const {docs, calculatedPricing, ...pagination} = payload.data;
          state.virtualMachines.items = docs;
          state.virtualMachines.info = {...pagination};
          state.virtualMachines.calculatedPricing = calculatedPricing || '\t—';

          state.isLoading = false;
        })
            .addCase(asyncActions.getVirtualMachines.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(asyncActions.getVirtualMachines.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(asyncActions.getDatabases.fulfilled, (state, {payload}) => {
                const {docs, calculatedPricing, ...pagination} = payload.data;
                state.databases.items = docs;
                state.databases.info = {...pagination};
                state.databases.calculatedPricing = calculatedPricing || '\t—';

                state.isLoading = false;
            })
            .addCase(asyncActions.getDatabases.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(asyncActions.getDatabases.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(asyncActions.getApps.fulfilled, (state, {payload}) => {
                const {docs, calculatedPricing, ...pagination} = payload.data;
                state.apps.items = docs;
                state.apps.info = {...pagination};
                state.apps.calculatedPricing = calculatedPricing || '\t—';

                state.isLoading = false;
            })
            .addCase(asyncActions.getApps.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(asyncActions.getApps.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(asyncActions.getKubernetes.fulfilled, (state, {payload}) => {
                const {docs, calculatedPricing, ...pagination} = payload.data;
                state.kubernetes.items = docs;
                state.kubernetes.info = {...pagination};
                state.kubernetes.calculatedPricing = calculatedPricing || '\t—';

                state.isLoading = false;
            })
            .addCase(asyncActions.getKubernetes.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(asyncActions.getKubernetes.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(asyncActions.getAllResources.fulfilled, (state, {payload}) => {
                const {docs, calculatedPricing, ...pagination} = payload.data;
                state.allResources.items = docs;
                state.allResources.info = {...pagination};
                state.allResources.calculatedPricing = calculatedPricing || '\t—';

                state.isLoading = false;
            })
            .addCase(asyncActions.getAllResources.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(asyncActions.getAllResources.rejected, (state) => {
                state.isLoading = false;
            })
      .addCase(asyncActions.getTeamsByBillingSettingId.fulfilled, (state, { payload }) => {
        const values = updateMany(state.values, payload.data);
        if (values === state.values) return state;
        return ({
          ...state,
          values
        });
      })
      .addCase(asyncActions.deactivate.pending, (state, { meta }) => {
        const teamId = meta.arg;
        const team = findById(teamId)(state.values);
        if (team) return ({
          ...state,
          values: updateOne(state.values, {
            ...team,
            deactivatedAt: new Date(),
            updatedAt: new Date(),
          })
        })

        return state;
      })
      .addCase(asyncActions.suspend.pending, (state, { meta }) => {
        const teamId = meta.arg;
        const team = findById(teamId)(state.values);
        if (team) return ({
          ...state,
          values: updateOne(state.values, {
            ...team,
            suspendedAt: new Date(),
            deactivatedAt: null,
            updatedAt: new Date(),
          })
        })

        return state;
      })
      .addCase(asyncActions.reactivate.pending, (state, { meta }) => {
        const teamId = meta.arg;
        const team = findById(teamId)(state.values);
        if (team) return ({
          ...state,
          values: updateOne(state.values, {
            ...team,
            suspendedAt: null,
            deactivatedAt: null,
            updatedAt: new Date(),
          }),
        })

        return state;
      })
      .addCase(asyncActions.suspend.fulfilled, (state, { payload }) => ({
        ...state,
        values: updateOne(state.values, payload.data)
      }))
      .addCase(asyncActions.deactivate.fulfilled, (state, { payload }) => ({
        ...state,
        values: updateOne(state.values, payload.data)
      }))
      .addCase(asyncActions.reactivate.fulfilled, (state, { payload }) => ({
        ...state,
        values: updateOne(state.values, payload.data)
      }))
    },
});

const teamStore = Object.freeze({
  ...adminSlice,
  asyncActions,
});

export default teamStore;
