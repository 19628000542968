// CORE
import { QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { ToastContainer } from "react-toastify";
// MUI
import { CssBaseline, ThemeProvider } from "@mui/material";
// STYLES
import "./styles/main.scss";
import "react-toastify/dist/ReactToastify.css";
// ASSETS
import { muiTheme } from "./assets/theme";
// COMPONENTS
import AppRouter from "./AppRouter";
import ProvideAuth from "./hoc/ProvideAuth";
import { queryClient } from "./libs/queryClient";

const App = () => {
  return (
    <ThemeProvider theme={muiTheme}>
      <CssBaseline/>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        limit={3}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        theme="colored"
        style={{ fontSize: "16px" }}
      />
      <ProvideAuth>
        <QueryClientProvider client={queryClient}>
          <AppRouter/>
        </QueryClientProvider>
      </ProvideAuth>

    </ThemeProvider>
  );
};

export default App;
