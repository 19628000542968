// CORE
import {createSlice} from '@reduxjs/toolkit';
// ACTIONS
import asyncActions from './asyncActions';

// helpers
import empty from '../../helpers/fp/array/empty';
import map from '../../helpers/fp/array/map';
import findById from '../../helpers/fp/array/findById';
import updateOne from '../../helpers/pp/array/updateOne';
import {paymentTypes} from '../../constants/team';
import findIndexById from '../../helpers/fp/array/findIndexById';

const userNe = (userId) => (billingSetting) => billingSetting.user !== userId;
const normalize = (billingSetting) => ({
  ...billingSetting,
  updatedAt: new Date(billingSetting.updatedAt),
  createdAt: new Date(billingSetting.createdAt),
});

const normalizes = map(normalize);

const billingSettingSlice = createSlice({
  name: 'billingSetting',
  initialState: empty(),
  reducers: {},
  // @ts-ignore
  extraReducers: builder => builder
    .addCase(asyncActions.getBillingSettings.fulfilled, (state, { payload }) => normalizes(payload.data))
    .addCase(asyncActions.getBillingSettingsByUserId.fulfilled, (state, { payload, meta }) => state.filter(userNe(meta.arg)).concat(normalizes(payload.data)))
    .addCase(asyncActions.getBillingSettingById.fulfilled, (state, { payload }) => {
      const billingSetting   = normalize(payload.data);
      const billingSettingId = billingSetting._id;

      const index = findIndexById(billingSettingId)(state);
      if (index < 0) return state.concat(billingSetting);
      if (billingSetting.updatedAt > state[index].updatedAt) return state.slice(0, index).concat(billingSetting, state.slice(index + 1))
      return state;
    })
    .addCase(asyncActions.setManualPaymentType.pending, (state, { meta }) => {
      const billingSetting = findById(meta.arg)(state);
      if (billingSetting) return updateOne(state, ({
        ...billingSetting,
        paymentType : paymentTypes.manual,
        updatedAt   : new Date(),
      }))
      return state;
    })
    .addCase(asyncActions.setAutomaticPaymentType.pending, (state, { meta }) => {
      const billingSetting = findById(meta.arg)(state);
      if (billingSetting) return updateOne(state, ({
        ...billingSetting,
        paymentType : paymentTypes.automatic,
        updatedAt   : new Date(),
      }))
      return state;
    })
});

const billingSettingStore = Object.freeze({
  ...billingSettingSlice,
  asyncActions,
});


export default billingSettingStore;
