// CORE
import { createSlice } from '@reduxjs/toolkit';
// ACTIONS
import asyncActions from './asyncActions';
import empty from '../../helpers/fp/array/empty';

const quotaSlice = createSlice({
  name: 'quota',
  initialState: empty(),
  reducers: {},
  extraReducers: builder => builder
    .addCase(asyncActions.getProviderQuotas.fulfilled, (_, { payload }) => payload.data)
  ,
});

export const selectProviderQuoteItems = state => state.quota;

const quotaStore = Object.freeze({
  ...quotaSlice,
  asyncActions,
});

export default quotaStore;
