import memoize from "../../decorators/memoize";
import always from "../../logic/always";
import indexedIndex from "../indexedIndex";

export default (prop) => {
  const byProp = indexedIndex(prop);
  const noIndex = always(-1);
  return memoize((id) => id
    ? (values) => byProp(values)(id)
    : noIndex
  );
};

