// CORE
import {createSlice} from '@reduxjs/toolkit';
// ACTIONS
import asyncActions from './asyncActions';
// CONSTANTS
import {defaultPagination} from '../../constants/pagination';
import empty from '../../helpers/fp/array/empty';
import updateMany from '../../helpers/pp/array/updateMany';
import updateOne from '../../helpers/pp/array/updateOne';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    items     : [],
    info      : defaultPagination,
    current   : null,
    isLoading : false,
    values    : empty(),
    tenants   : [],
  },
  reducers: {
    clearUsers(state) {
      state.items = [];
      state.info = defaultPagination;

      return state;
    },
    clearUser(state) {
      state.current = null;
      state.tenants = [];

      return state;
    },
    addUser(state, {payload}) {
      const userInTheList = state.items.find((user) => user._id === payload._id);
      if (userInTheList) {
        return state;
      }
      const users = state.items.slice(0, state.info.limit - 1);
      state.items = [payload, ...users];
      state.info.totalDocs = state.info.totalDocs + 1;

      return state;
    },
  },
  extraReducers: builder => {
    builder.addCase(asyncActions.getUsers.fulfilled, (state, {payload}) => {
      const {docs, ...pagination} = payload.data;
      state.items = docs;
      state.info = {...pagination};
      state.isLoading = false;
      state.values = updateMany(state.values, docs);
      return state;
    }).
      addCase(asyncActions.getUsers.pending, (state) => {
        state.isLoading = true;
      }).
      addCase(asyncActions.getUsers.rejected, (state) => {
        state.isLoading = false;
      }).
      addCase(asyncActions.getDeletionRequests.fulfilled,
        (state, {payload}) => {
          const {docs, ...pagination} = payload.data;
          state.items = docs;
          state.info = {...pagination};
          state.values = updateMany(state.values, docs);
          state.isLoading = false;
          return state;
        }).
      addCase(asyncActions.getDeletionRequests.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(asyncActions.getDeletionRequests.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(asyncActions.getUser.fulfilled, (state, {payload}) => {
        const user = payload.data;
        state.current = user;
        state.values = updateOne(state.values, user);
        return state;
      })
      .addCase(asyncActions.getUserById.fulfilled, (state, { payload }) => ({
        ...state,
        values: updateOne(state.values, payload.data),
      }))
      .addCase(asyncActions.activateUser.fulfilled, (state,{payload}) => {
        const user = payload.data;
        state.current = {...state.current, ...user};
        state.values = updateOne(state.values, user);
        return state;
      })
      .addCase(asyncActions.suspendUser.fulfilled, (state,{payload}) => {
        const user = payload.data;
        state.current = {...state.current, ...user};
        state.values = updateOne(state.values, user);
        return state;
      })
      .addCase(asyncActions.deactivateUser.fulfilled, (state,{payload}) => {
        const user = payload.data;
        state.current = {...state.current, ...user};
        state.values = updateOne(state.values, user);
        return state;
      }).addCase(asyncActions.deleteTenantUser.fulfilled, (state, { meta }) => {
        state.items = state.items.filter(user => user._id !== meta.arg);
        return state;
      }).addCase(asyncActions.getUserTenats.fulfilled, (state, { payload }) => {
        state.tenants = payload.data;
        return state;
      })
  },
});

const userStore = Object.freeze({
  ...userSlice,
  asyncActions,
});

export default userStore;
