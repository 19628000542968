// CORE
import {useCallback, useEffect, useMemo, useState} from 'react';
import {io} from 'socket.io-client';
import {useDispatch, useSelector} from 'react-redux';
// STORE
import logStore from '../../store/log';
import {selectAdmin} from '../../store/admin/selector';
// CONSTANTS
import {jwtTokens} from '../../constants/auth/tokens';
import {adminRoles} from '../../constants/roles';

export const useServerLogs = () => {
  const [ socket, setSocket ] = useState(null);
  const admin = useSelector(selectAdmin);
  const dispatch = useDispatch();

  const accessToken = localStorage.getItem(jwtTokens.ACCESS_TOKEN);
  const isSuperAdmin = admin.role === adminRoles.SUPER_ADMIN;

  const options = useMemo(() => (accessToken && isSuperAdmin ? {
    path        : '/logs',
    autoConnect : false,
    extraHeaders: {
      ['Authorization']: `Bearer ${accessToken}`,
    },
  } : null), [ accessToken, isSuperAdmin ]);

  const handleLogEvent = useCallback(
    (log) => dispatch(logStore.actions.setLogs(log)), []);

  useEffect(() => {
    if (options) setSocket(io(process.env.REACT_APP_BE_URL, options));
  }, [ options ]);

  useEffect(() => {
    return () => {
      dispatch(logStore.actions.clearLogs());
    };
  }, []);

  useEffect(() => {
    if (socket) socket.connect();

    return () => {
      if (socket) socket.disconnect();
    };
  }, [socket]);

  useEffect(() => {
    if (socket) {
      socket.on('log', handleLogEvent)
    }

    return () => {
      if (socket) {
        socket.off('log', handleLogEvent)
      }
    }
  }, [socket])

  return [socket, setSocket];
}
