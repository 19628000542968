// AXIOS
import apiClient from "../../libs/apiClient";
// HELPERS
import {objectToURL} from "../../helpers/query";

const deleteVirtualMachine = (id) => apiClient.delete(`/virtual-machines/${id}`);

const getVirtualMachines = (params) => apiClient.get(`/virtual-machines?${objectToURL(params)}`);

const getVirtualMachineById = (id) => apiClient.get(`/virtual-machines/${id}`);
const getVirtualMachine = (id) => apiClient.get(`/virtual-machines/${id}`);

export default {
  deleteVirtualMachine,
  getVirtualMachines,
  getVirtualMachineById,
  getVirtualMachine
}
