// CORE
import React, { useEffect, useState } from "react";
import {useDispatch} from "react-redux";
// STORE
import adminStore from '../../store/admin'
// COMPONENTS
import PageLoader from "../../components/shared/PageLoader";


const ProvideAuth = ({ children }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const apiKey = localStorage.getItem('api-key');

    useEffect(() => {
        if (!apiKey) {
            setLoading(false);
            return;
        }

        (async()=>{
           await dispatch(adminStore.asyncActions.getProfile());
           setLoading(false);
        })()
    }, [apiKey])

    if (loading) return <PageLoader/>

    return children
}

export default ProvideAuth;