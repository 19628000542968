// CORE
import { createSlice } from '@reduxjs/toolkit';

// ACTIONS
import asyncActions from './asyncActions';

// CONSTANTS
import eq from '../../helpers/pp/object/eq';
import empty from '../../helpers/fp/object/empty';

const getPublicConfig = () => {
  const publicConfigStr = localStorage.getItem('publicConfig');
  if (publicConfigStr) {
    try {
      return JSON.parse(publicConfigStr) || empty();
    } catch (e) {
      localStorage.removeItem('publicConfig');
    }
  }
  return empty();
};

const setPublicConfig = (publicConfig) => (localStorage.setItem('publicConfig', JSON.stringify(publicConfig)), publicConfig);

const publicConfigSlice = createSlice({
  name          : 'publicConfig',
  initialState  : getPublicConfig(),
  reducers      : empty(),
  extraReducers : builder => {
    builder
      .addCase(asyncActions.getPublicConfig.fulfilled, (state, { payload }) => eq(state, payload) ? state : setPublicConfig(payload))
  },
});

const publicConfigStore = Object.freeze({
  ...publicConfigSlice,
  asyncActions,
});

export default publicConfigStore;
