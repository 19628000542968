import React from "react";
import { Link } from "react-router-dom";
import publicUrl from "../../../helpers/publicUrl";

const PublicLink = ({ to, children, ...props }) => (
  <Link to={publicUrl(to)} {...props}>
    {children}
  </Link>
);

export default PublicLink;
